 import type { Tenant } from '@pigello/pigello-matrix';


import { useGetInstance, useGetList } from '@/requests/hooks';

import type {
  useGetGenericInstanceProps,
  useGetGenericListProps,
} from '@/requests/types';


export const useGetTenant = (
  options: useGetGenericInstanceProps<Tenant>
) => {
  return useGetInstance<Tenant>({
    ...options,
    modelName: 'tenant'
  });
};

export const useGetTenantList = (
  options: useGetGenericListProps<Tenant>
) => {
  return useGetList<Tenant>({
    ...options,
    modelName: 'tenant'
  });
};
