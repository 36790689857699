import { useHandleReportsOffLoading } from './special-cases/reports';
import type { PigelloEventListener } from './types';
import { useEventSubscription } from './use-event-subscription';

export const useReactQuerySubscription = () => {
  const { handleReportsOffLoading, REPORT_OFF_LOADING_EVENT_IDENTIFIERS } =
    useHandleReportsOffLoading();
  const listeners = [
    [REPORT_OFF_LOADING_EVENT_IDENTIFIERS, handleReportsOffLoading],
  ] as const;

  const specialEventMap = new Map<string, PigelloEventListener[]>();

  for (const [identifiers, handler] of listeners) {
    for (const identifier of identifiers) {
      const existing = specialEventMap.get(identifier);
      if (existing) {
        existing.push(handler);
      } else {
        specialEventMap.set(identifier, [handler]);
      }
    }
  }

  const specialListeners: PigelloEventListener = (event, { queryClient }) => {
    const handlers = specialEventMap.get(event.event_identifier);
    if (!handlers) {
      return;
    }
    for (const handler of handlers) {
      handler(event, { queryClient });
    }
  };

  useEventSubscription(specialListeners);
};
