'use client';
import { Urls } from '@/lib/urls';
import { getCookies } from 'cookies-next';
import { DateTime } from 'luxon';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

const ANONYMOUS_ROUTES = [
  Urls.integrationFlow.fortnox.activation,
  Urls.integrationFlow.vismaEEconomy.activation,
  Urls.f.anonymousLinks,
];

export default function useAuthState() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const pathname = usePathname();
  const cookies = getCookies();
  const token = cookies?.token;
  const token_exp = cookies?.token_exp;
  const router = useRouter();

  useEffect(() => {
    // not loaded cookies yet
    if (!cookies) {
      return;
    }

    const noAction = ANONYMOUS_ROUTES.some((r) => pathname.includes(r));
    // bail on rerouting
    if (noAction) {
      setIsAuthorized(true);
      return;
    }
    const isLoginRoute = pathname.includes('/login');
    const hasToken = !!token;
    const hasTokenExp = !!token_exp;
    const tokenExpirationDate = hasTokenExp
      ? DateTime.fromISO(decodeURIComponent(token_exp))
      : undefined;
    const tokenExpired = tokenExpirationDate
      ? tokenExpirationDate < DateTime.now()
      : true;

    if (isLoginRoute) {
      if (hasToken && !tokenExpired) {
        router.replace(Urls.dashboard.home);
      } else {
        // do nothing
        setIsAuthorized(true);
        return;
      }
    } else {
      if (hasToken && !tokenExpired) {
        // do nothing
        setIsAuthorized(true);
        return;
      } else {
        router.replace(Urls.login.credentials);
      }
    }
  }, [pathname, token, token_exp, cookies, router]);

  return isAuthorized;
}
