import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useWebSocket } from '../use-web-socket';
import { useGetSelf } from '../useGetSelf';
import { EVENT_IDENTIFIER_KEYS_TO_INVALIDATE_ON } from './constants';
import type { EventContext, PigelloEventListener } from './types';
import { getModelName } from './utils';

export const useEventSubscription = (
  specialListeners: PigelloEventListener
) => {
  const queryClient = useQueryClient();
  const { data } = useGetSelf();
  const { lastMessageParsed: message } = useWebSocket();
  useEffect(() => {
    if (!data || !message || message.invoke_type !== 0) {
      return;
    }

    for (const event of message.pigello_events) {
      if (event.invoked_by_user_id === data.id) {
        return;
      }

      const modelName = getModelName(event.content_type);
      const objectId = event.object_id;

      const context: EventContext = { queryClient };
      // Run special listeners
      specialListeners(event, context);

      const identifier = event.event_identifier.split('.').at(-1);
      if (
        !identifier ||
        !EVENT_IDENTIFIER_KEYS_TO_INVALIDATE_ON.includes(identifier)
      ) {
        return;
      }

      if (modelName && objectId) {
        queryClient.invalidateQueries({
          queryKey: [modelName, { id: objectId }],
        });
      }
    }
  }, [queryClient, message, data, specialListeners]);
};
