import { downloadFile } from '@/app/dashboard/reports/utils';
import type { PigelloEvent } from '@/hooks/use-web-socket/types';
import { get } from '@/requests/api/get';
import { QUERY_KEYS } from '@/requests/constants';
import { useOffLoadingStore } from '@/store';
import type { ReportResult } from '@pigello/pigello-matrix';
import { toast } from 'sonner';
import type { EventContext } from '../types';
import { getModelName } from '../utils';

function fetchAndDownloadReportOnClick(objectId: string) {
  toast.promise(
    get<ReportResult>({ id: objectId, modelName: 'reportresult' }),
    {
      loading: 'Laddar ner...',
      success: async (report) => {
        if (report?.file) {
          await downloadFile({
            downloadUrl: report.file.get,
            fileEnding: 'xlsx',
            fileName: `rapport-${report.createdAt}`,
          });
        }
        return 'Rapporten har laddats ner';
      },
      error: () => {
        return 'Misslyckades med att ladda ner rapporten';
      },
    }
  );
}
export const REPORT_OFF_LOADING_EVENT_IDENTIFIERS = [
  'reports.reportresult.instanceupdated',
] as const;

export function useHandleReportsOffLoading() {
  const { isObjectOffLoaded, removeOffLoadedIdForModel } = useOffLoadingStore();

  function handleReportsOffLoading(event: PigelloEvent, context: EventContext) {
    const modelName = getModelName(event.content_type);
    const objectId = event.object_id;

    if (!objectId || !modelName || !isObjectOffLoaded(modelName, objectId)) {
      return;
    }

    removeOffLoadedIdForModel(modelName, objectId);

    context.queryClient.invalidateQueries({
      queryKey: [modelName, QUERY_KEYS.LIST],
    });

    toast.info('Rapporten har genererats', {
      description: 'Klicka på knappen för att ladda ner',
      action: {
        label: 'Ladda ner',
        onClick: () => fetchAndDownloadReportOnClick(objectId),
      },
    });
  }

  return {
    handleReportsOffLoading,
    REPORT_OFF_LOADING_EVENT_IDENTIFIERS,
  };
}
